export interface NylasConnectURLPrams {
  client_id: string;
  redirect_uri: string;
  login_hint?: string;
  response_type: string;
  scope?: string;
  scopes?: string;
}

export interface NativeConnectURLPrams {
  client_id: string;
  name: string;
  email_address: string;
  provider: string;
  settings: any;
  scopes: string;
}

export const generateConnectURL = (params: NylasConnectURLPrams): string =>
  `https://api.nylas.com/oauth/authorize?${Object.entries(params)
    .map((entry) => {
      const [key, value] = entry;
      return `${key}=${value}`;
    })
    .join('&')}`;

export const generateNativeConnectURL = (
  params: NativeConnectURLPrams
): string =>
  `https://api.nylas.com/connect/authorize?${Object.entries(params)
    .map((entry) => {
      const [key, value] = entry;
      return `${key}=${value}`;
    })
    .join('&')}`;

export const personalCalParams = {
  client_id: process.env.REACT_APP_NYLAS_PERSONAL_CALENDAR_CLIENT_ID || '',
  redirect_uri: `${window.location.origin}/account/connect-with-calendar?calendar=personal`,
  response_type: 'code',
  scopes: 'calendar',
};
