import React, { useEffect, useState } from 'react';
import { useContext } from 'react';
import { Query } from 'react-apollo';
import { useHistory } from 'react-router-dom';
import {
  Button,
  Container,
  Typography,
  Box,
  Badge,
  Divider,
  Grid,
  Checkbox,
} from '@material-ui/core';
import { Spinner } from 'react-bootstrap';

import { AppContext } from '../../../contexts';

import Header from '../../../components/layout/Header';
import AccountMenu from '../../../components/AccountMenu';
import CalendarAccountDialog from './CalendarAccountDialog';
import RemovePersonalAccountDialog from './RemovePersonalAccountDialog';

import client from '../../../apollo';
import Loader from '../../../components/Loader';
import Toast from '../../../components/Toast';
import { Tostcongigs } from '../account/ProfilePage';

import {
  GET_PERSONAL_EMAIL_CALENDARS,
  GET_SYNCED_PERSONAL_CALENARS,
  SET_PERSONAL_AUTH_TOKEN,
  SYNC_EMAIL_CALENDAR,
} from './gql';
import { generateConnectURL, personalCalParams } from '../../../common/nylas';
import { fetchSyncedPersonalEmailCalendarsQuery } from './gql/__generated__/fetchSyncedPersonalEmailCalendarsQuery';

import CALENDAR_CONNECTED_ICON from '../../../img/checked-circle-icon.svg';
import { connectCalendarStyles } from './styles';

export interface IAllCalendarList {
  name: string;
  calendarId: string;
  isPrimary: boolean;
}

export interface IAllCheckedCalendars {
  name: string;
  calendarId: string;
  emailAddress: string;
  isPrimary?: boolean;
}

const ConnectWithCalendar = () => {
  const history = useHistory();
  const classes = connectCalendarStyles();
  const { user, setUser } = useContext(AppContext);

  const urlParams = new URLSearchParams(window.location.search);
  const code = urlParams.get('code');

  const [allPersonalCalendarList, setAllPersonalCalendarList] = useState<
    IAllCalendarList[]
  >([]);
  const [checkedCalendars, setCheckedCalendars] = useState<
    IAllCheckedCalendars[]
  >([]);
  const [connectedEmailAddress, setConnectedEmailAddress] = useState('');
  const [open, setOpen] = useState(false);
  const [openToast, setOpenToast] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [isRefetchMoreCalendars, setRefetchMoreCalendars] = useState(false);
  const [toast, setToast] = useState<Tostcongigs>({
    message: '',
    type: undefined,
  });

  const params = {
    client_id: process.env.REACT_APP_NYLAS_CLIENT_ID || '',
    redirect_uri:
      process.env.REACT_APP_REDIRECT_URI ||
      'https://staging.advisors.collegeadvisor.com/callback',
    login_hint: user?.email ?? '',
    response_type: 'code',
    scope: 'calendar',
  };

  const getAuthToken = async () => {
    try {
      if (code) {
        setLoading(true);
        const { data } = await client.mutate({
          mutation: SET_PERSONAL_AUTH_TOKEN,
          variables: {
            code,
          },
          fetchPolicy: 'no-cache',
        });

        const {
          message,
          success,
          emailAddress,
          calendarList,
        } = data?.setNylasPersonalCalendarAuthToken;

        if (success && calendarList.length) {
          setAllPersonalCalendarList(calendarList);
          setConnectedEmailAddress(emailAddress);

          if (
            emailAddress &&
            calendarList?.length === 1 &&
            calendarList?.find(
              (cal: { name: string; calendarId: string }) =>
                cal?.name === 'Emailed events'
            )
          )
            setRefetchMoreCalendars(true);

          if (user && !user?.isPersonalCalendarConnected)
            setUser({ ...user, isPersonalCalendarConnected: true });
          setOpen(true);
        } else if (!success) {
          setToast({
            message: message,
            type: 'error',
          });
          setOpenToast(true);
        }
      }
    } catch (err) {
      setToast({
        message: 'Something Went Wrong!',
        type: 'error',
      });
      setOpenToast(true);
    } finally {
      setLoading(false);
      history.replace({ search: '' });
    }
  };

  const reFetchPersonalEmailCalendars = async ({
    email,
  }: {
    email: string;
  }) => {
    try {
      setRefetchMoreCalendars(true);

      const { data } = await client.query({
        query: GET_PERSONAL_EMAIL_CALENDARS,
        fetchPolicy: 'network-only',
        variables: { email },
      });

      setAllPersonalCalendarList(
        data?.fetchPersonalEmailCalendars?.calendarList
      );
    } catch (err) {
      console.log('Erro::', err);
    } finally {
      setRefetchMoreCalendars(false);
    }
  };

  useEffect(() => {
    getAuthToken();
  }, []);

  useEffect(() => {
    if (isRefetchMoreCalendars)
      setTimeout(() => {
        reFetchPersonalEmailCalendars({ email: connectedEmailAddress });
      }, 15000);
  }, [isRefetchMoreCalendars]);

  const handleCheckBoxClick = async (
    name: string,
    calendarId: string,
    emailAddress: string
  ) => {
    try {
      setLoading(true);
      const isChecked = checkedCalendars?.find(
        (cal) => cal.calendarId === calendarId
      )
        ? false
        : true;

      if (!isChecked) {
        const newCheckedCals = checkedCalendars?.filter(
          (cal) => cal.calendarId !== calendarId
        );
        setCheckedCalendars(newCheckedCals);
      } else {
        const newCheckedCals = [
          ...checkedCalendars,
          { calendarId, name, emailAddress },
        ];
        setCheckedCalendars(newCheckedCals);
      }

      await client.mutate({
        mutation: SYNC_EMAIL_CALENDAR,
        variables: {
          calendars: [
            {
              calendarId,
              calendarName: name,
              isChecked: isChecked,
              email_address: emailAddress,
            },
          ],
        },
      });
    } catch (err) {
      console.log('Error::', err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <React.Fragment>
      <Header />

      {isLoading && <Loader />}

      <Toast
        open={openToast}
        close={() => {
          setOpenToast(false);
        }}
        {...toast}
      />

      <Query<fetchSyncedPersonalEmailCalendarsQuery>
        query={GET_SYNCED_PERSONAL_CALENARS}
        fetchPolicy="network-only"
        onCompleted={(data) => {
          const { fetchSyncedPersonalEmailCalendars } = data;
          if (fetchSyncedPersonalEmailCalendars) {
            const { connectedAcounts } = fetchSyncedPersonalEmailCalendars;

            const alreadyConnectedCals = (
              connectedAcounts?.map((acc) =>
                acc?.connectedCalendars?.map((cal) => ({
                  name: cal?.calendarName || 'N/A',
                  calendarId: cal?.calendarId || '',
                  emailAddress: acc.email,
                  isPrimary: cal?.isPrimary,
                }))
              ) || []
            ).flat() as IAllCheckedCalendars[];

            if (alreadyConnectedCals.length) {
              setCheckedCalendars(alreadyConnectedCals);
            }
          }
        }}
      >
        {({ data, refetch, loading: ApiLoading }) => {
          const alreadyConnectedAccounts =
            data?.fetchSyncedPersonalEmailCalendars?.connectedAcounts || [];

          const accounts = alreadyConnectedAccounts?.map((acc) => ({
            email: acc?.email || 'N/A',
            calendars: [
              ...(acc?.connectedCalendars?.map((cal) => ({
                calednarId: cal?.calendarId || 'N/A',
                name: cal?.calendarName || 'N/A',
                isPrimary: cal?.isPrimary,
              })) || []),
              ...(acc?.nonConnectedCalendars?.map((cal) => ({
                calednarId: cal?.calendarId || 'N/A',
                name: cal?.calendarName || 'N/A',
                isPrimary: cal?.isPrimary,
              })) || []),
            ].sort((a) => (a?.isPrimary ? -1 : 1)),
          }));

          return (
            <Container className={classes.accountStyle}>
              <Typography variant="h3">My Account</Typography>

              <Grid container spacing={2}>
                <Grid item md={3} sm={12} xs={12}>
                  <AccountMenu />
                </Grid>

                <Grid item md={9} sm={12} xs={12}>
                  <Grid className={classes.accountRightContainer}>
                    <Box
                      display="flex"
                      alignItems="center"
                      className="headerBox"
                    >
                      <Typography variant="h4">
                        Connect Your CollegeAdvisor.com Calendar
                      </Typography>

                      <Badge>Required</Badge>
                    </Box>

                    <Typography variant="body1" className="conflictsText">
                      By doing this we will check for conflicts and prevent
                      double bookings for student meetings
                    </Typography>

                    {!user?.nylas_access_token ? (
                      <Button
                        href={generateConnectURL(params)}
                        rel="noopener noreferrer"
                        variant="contained"
                        className={classes.connectCalendarButton}
                      >
                        Connect CollegeAdvisor Calendar
                      </Button>
                    ) : (
                      <Button
                        disabled={true}
                        variant="text"
                        className={classes.calendarConnected}
                        style={{ textTransform: 'inherit' }}
                      >
                        <img src={CALENDAR_CONNECTED_ICON} alt="check" />

                        <span className="calendarConnectedSpan">
                          CollegeAdvisor.com Calendar Connected
                        </span>
                      </Button>
                    )}

                    {process.env.REACT_APP_ENABLE_PERSONAL_CALENDAR ===
                      'TRUE' || user?.email === 'jmaloto@collegeadvisor.com' ? (
                      <>
                        <Grid>
                          <Divider />
                        </Grid>
                        <Typography variant="h6">
                          Connect Other Calendar Accounts
                        </Typography>
                        <Typography variant="body1">
                          Connect other Google or Outlook accounts you would
                          like us to consider to prevent double bookings.
                        </Typography>

                        {ApiLoading ? (
                          <Box>
                            <Spinner animation="border" size="sm" /> Loading...
                          </Box>
                        ) : (
                          !!accounts.length && (
                            <Grid className={classes.removeConnectionGutter}>
                              {accounts?.map((account, index) => (
                                <>
                                  <Box
                                    className="removeConnection"
                                    display="flex"
                                    alignItems="center"
                                  >
                                    <Typography variant="body2">
                                      {account?.email}
                                    </Typography>
                                    <RemovePersonalAccountDialog
                                      email_address={account?.email || ''}
                                      refetch={refetch}
                                    />
                                  </Box>

                                  {account?.calendars?.map((calendar) => (
                                    <>
                                      <Grid
                                        className={classes.checkboxComponent}
                                      >
                                        <div className="checkboxLeftGutter">
                                          <Checkbox
                                            color="default"
                                            onClick={(e) => {
                                              handleCheckBoxClick(
                                                calendar.name,
                                                calendar.calednarId,
                                                account?.email
                                              );
                                            }}
                                            checked={
                                              checkedCalendars?.find(
                                                (cal) =>
                                                  cal.calendarId ===
                                                  calendar.calednarId
                                              )
                                                ? true
                                                : false
                                            }
                                          />
                                          <label className="checkboxText">
                                            {calendar?.name}{' '}
                                            {calendar?.isPrimary
                                              ? '(Primary)'
                                              : ''}
                                          </label>
                                        </div>
                                      </Grid>
                                    </>
                                  ))}

                                  {index !== (accounts?.length || 0) - 1 && (
                                    <Divider className="checkboxDivider" />
                                  )}
                                </>
                              ))}
                            </Grid>
                          )
                        )}

                        <Button
                          href={generateConnectURL(personalCalParams)}
                          variant="outlined"
                          disabled={ApiLoading}
                          className={classes.connectCalendarButton}
                        >
                          Connect Other Account
                        </Button>
                      </>
                    ) : (
                      <></>
                    )}

                    <CalendarAccountDialog
                      {...{
                        open,
                        setOpen,
                        refetch,
                        connectedEmailAddress,
                        isRefetchMoreCalendars,
                        allPersonalCalendarList,
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Container>
          );
        }}
      </Query>
    </React.Fragment>
  );
};

export default ConnectWithCalendar;
