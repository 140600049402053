import gql from 'graphql-tag';

/* (QUERY) Fetch student query */
export const ALL_MENTOR_STUDENTS_QUERY = gql`
  query AllMentorStudentsQuery(
    $searchQuery: String
    $MentorId: Int
    $graduated: Boolean
    $limit: Int
    $bringAll: Boolean
  ) {
    allMentorStudents(
      input: {
        searchQuery: $searchQuery
        MentorId: $MentorId
        graduated: $graduated
        limit: $limit
        bringAll: $bringAll
      }
    ) {
      nextRecord
      activeStudentsCount
      graduatedStudentsCount
      graduatedStudents {
        student {
          id
          firstName
          lastName
        }
      }
      students {
        student {
          id
          firstName
          lastName
        }
      }
    }
  }
`;

export const GET_STUDENT_ENTITLEMENTS_STAT = gql`
  query StudentEntitlementsQuery($studentId: Int!, $advisorId: Int!) {
    Student(studentId: $studentId, advisorId: $advisorId) {
      id
      firstName
      lastName
      isPremium
      premiumPackageType
      premiumTotalVideoCount
      premiumVideoCountUsed
      premiumTotalOfflineCount
      giveDressRehearsalsEntitlements {
        isPremiumElite
        dressRehearsalsUsedCount
      }
      premiumOfflineCountUsed {
        essayCount
        offlineResearch
        MeetingWithAdvisor
        milestoneTeamTotal
        Other
        dressRehearsalCount
        correspondenceTotal
      }
      studentApplications {
        id
        applicationRequirement {
          id
          college {
            id
            name
            ParentCollegeId
          }
        }
      }
      studentTrial {
        id
        trialType
        trialStartedAt
        trialCompletedAt
      }
    }
  }
`;

/* (QUERY) Fetch Avsior students + other students query */
export const MENTOR_ADVISOR_STUDENTS_QUERY = gql`
  query MentorAdvisingStudentsQuery(
    $searchQuery: String
    $MentorId: Int
    $milestoneTeam: String
  ) {
    mentorAdvisingStudents(
      input: {
        searchQuery: $searchQuery
        MentorId: $MentorId
        milestoneTeam: $milestoneTeam
      }
    ) {
      students {
        firstName
        lastName
        id
        initialKickOffDate
        email
      }
      studentsCount
      mentorStudentIds
    }
  }
`;

/* (QUERY) Fetch time log stats */
export const GET_STUDENT_TRIAL = gql`
  query GetStudentTrialQuery($studentId: Int) {
    getStudentTrial(input: { studentId: $studentId }) {
      student {
        id
        firstName
        lastName
        email
        highSchoolYear
      }
      studentTrial {
        id
        trialType
        trialStartedAt
        trialCompletedAt
        isParentAttendedMeeting
        studentAreasOfInterest
        topicsCoverdInMeeting
        adviceForStudent
      }
    }
  }
`;

export const UPDATE_USER_TRIAL_MUTATION = gql`
  mutation UpdateUserTrialMutation(
    $trialId: Int!
    $studentId: Int
    $isParentAttendedMeeting: String
    $studentAreasOfInterest: [String]
    $topicsCoverdInMeeting: [String]
    $adviceForStudent: String
  ) {
    updateUserTrial(
      input: {
        trialId: $trialId
        studentId: $studentId
        isParentAttendedMeeting: $isParentAttendedMeeting
        studentAreasOfInterest: $studentAreasOfInterest
        topicsCoverdInMeeting: $topicsCoverdInMeeting
        adviceForStudent: $adviceForStudent
      }
    ) {
      success
      message
    }
  }
`;

/* (QUERY) to check if mentor already onBoarded */
export const CHECK_ONBOARDED = gql`
  query ALL_MENTOR_TIME_LOGS_QUERY($limit: Int, $MentorId: Int) {
    allMentorTimeLogs(input: { limit: $limit, MentorId: $MentorId }) {
      mentorTimeLogs {
        onBoarding {
          onBoarded
        }
      }
    }
  }
`;

/* (MUTATION) Fetch single timelog */
export const DELETE_TIME_LOG = gql`
  mutation DeleteTimeLog($id: Int!) {
    deleteTimeLog(input: { id: $id }) {
      message
    }
  }
`;

/* (MUTATION) add new time log */
export const CREATE_MENTOR_TIMELOG = gql`
  mutation CreateMentorTimeLog(
    $StudentId: Int
    $workType: String!
    $workActivity: String!
    $activityAt: Date!
    $activityDuration: Int
    $activityDescription: String
    $PremiumEntitlementId: Int
    $PaymentCategoryId: Int
    $missCall: Boolean
    $note: String
    $topic: [String]
    $collegeId: Int
    $teamId: Int
    $durationNote: String
    $milestoneTeamId: Int
    $isFullTime: Boolean
    $amount: Float
    $otherAdvisorIds: [Int]
    $GroupId: String
    $airtableSubmitted: Boolean
    $milestone: String
    $serviceElement: String
    $AdvisorsBookingId: Int
    $otherMeetingDescription: String
    $noteForExtendedMeeting: String
    $noteForNoOrientationCall: String
    $entityType: String
    $entityIds: [Int]
    $OtherApplicationsIds: [Int]
    $isCommonApp: Boolean
    $isCoalitionApp: Boolean
    $isOtherStrategy: Boolean
    $isProjectWork: Boolean
  ) {
    createMentorTimeLog(
      input: {
        StudentId: $StudentId
        workType: $workType
        milestoneTeamId: $milestoneTeamId
        workActivity: $workActivity
        activityAt: $activityAt
        activityDuration: $activityDuration
        activityDescription: $activityDescription
        PremiumEntitlementId: $PremiumEntitlementId
        PaymentCategoryId: $PaymentCategoryId
        missCall: $missCall
        note: $note
        durationNote: $durationNote
        topic: $topic
        collegeId: $collegeId
        teamId: $teamId
        isFullTime: $isFullTime
        amount: $amount
        otherAdvisorIds: $otherAdvisorIds
        GroupId: $GroupId
        airtableSubmitted: $airtableSubmitted
        milestone: $milestone
        serviceElement: $serviceElement
        AdvisorsBookingId: $AdvisorsBookingId
        noteForExtendedMeeting: $noteForExtendedMeeting
        noteForNoOrientationCall: $noteForNoOrientationCall
        otherMeetingDescription: $otherMeetingDescription
        entityType: $entityType
        entityIds: $entityIds
        OtherApplicationsIds: $OtherApplicationsIds
        isCommonApp: $isCommonApp
        isCoalitionApp: $isCoalitionApp
        isOtherStrategy: $isOtherStrategy
        isProjectWork: $isProjectWork
      }
    ) {
      mentorTimeLog {
        id
      }
      message
    }
  }
`;

/* (MUTATION) update new time log */
export const UPDATE_MENTOR_TIMELOG = gql`
  mutation UpdateMentorTimeLog(
    $id: Int!
    $StudentId: Int
    $workType: String!
    $workActivity: String!
    $activityAt: Date!
    $activityDuration: Int
    $activityDescription: String
    $PremiumEntitlementId: Int
    $PaymentCategoryId: Int
    $missCall: Boolean
    $note: String
    $topic: [String]
    $collegeId: Int
    $teamId: Int
    $durationNote: String
    $isFullTime: Boolean
    $amount: Float
    $otherAdvisorIds: [Int]
    $airtableSubmitted: Boolean
    $milestone: String
    $serviceElement: String
    $AdvisorsBookingId: Int
    $otherMeetingDescription: String
    $noteForExtendedMeeting: String
    $noteForNoOrientationCall: String
    $entityType: String
    $entityIds: [Int]
    $timeLogAssociationId: Int
    $isCommonApp: Boolean
    $milestoneTeamId: Int
    $isCoalitionApp: Boolean
    $isOtherStrategy: Boolean
    $isProjectWork: Boolean
  ) {
    updateMentorTimeLog(
      input: {
        id: $id
        StudentId: $StudentId
        workType: $workType
        workActivity: $workActivity
        activityAt: $activityAt
        activityDuration: $activityDuration
        activityDescription: $activityDescription
        PremiumEntitlementId: $PremiumEntitlementId
        PaymentCategoryId: $PaymentCategoryId
        missCall: $missCall
        note: $note
        durationNote: $durationNote
        topic: $topic
        collegeId: $collegeId
        teamId: $teamId
        isFullTime: $isFullTime
        amount: $amount
        milestoneTeamId: $milestoneTeamId
        otherAdvisorIds: $otherAdvisorIds
        airtableSubmitted: $airtableSubmitted
        milestone: $milestone
        serviceElement: $serviceElement
        AdvisorsBookingId: $AdvisorsBookingId
        otherMeetingDescription: $otherMeetingDescription
        noteForExtendedMeeting: $noteForExtendedMeeting
        noteForNoOrientationCall: $noteForNoOrientationCall
        entityType: $entityType
        entityIds: $entityIds
        timeLogAssociationId: $timeLogAssociationId
        isCommonApp: $isCommonApp
        isCoalitionApp: $isCoalitionApp
        isOtherStrategy: $isOtherStrategy
        isProjectWork: $isProjectWork
      }
    ) {
      message
    }
  }
`;

export const ADVISOR_TEAMS = gql`
  query advisorTeamsQuery($MentorId: Int!) {
    advisorTeams(MentorId: $MentorId) {
      teams {
        id
        name
      }
    }
  }
`;

export const PENDING_MENTOR_TIMELOG = gql`
  query pendingMentorTimeLogQuery($AdvisorId: Int!) {
    mentorPendingTimeLogs(input: { AdvisorId: $AdvisorId }) {
      pendingTimeLogs {
        id
        StudentId
        workType
        workActivity
        activityAt
        note
        durationNote
        missCall
        activityDuration
        GroupId
        MentorTimeLogAssociations {
          id
          advisorBookingId
          allAssociatedAdvisors {
            firstName
            lastName
          }
          otherMeetingDescription
          noteForExtendedMeeting
          noteForNoOrientationCall
        }
        student {
          id
          firstName
          lastName
          email
          initialKickOffDate
        }
        mentor {
          firstName
          lastName
        }
        milestone
        serviceElement
      }
    }
  }
`;

export const ALL_COLLEGE_APPLICATIONS = gql`
  query AllCollegeApplicationsQuery(
    $limit: Int
    $page: Int
    $searchQuery: String
    $topHundred: Boolean
    $collegesToExcludeIds: [Int]
  ) {
    allColleges(
      input: {
        limit: $limit
        page: $page
        searchQuery: $searchQuery
        topHundred: $topHundred
        collegesToExcludeIds: $collegesToExcludeIds
      }
    ) {
      nextRecord
      collegesCount
      colleges {
        id
        name
        logo
        applicationCreated
      }
    }
  }
`;

export const CREATE_STUDENT_APPLICATION_MUTATION = gql`
  mutation CreateApplicationForStudentMutation(
    $collegeIds: [Int!]!
    $StudentId: Int
    $studentOtherApplication: Boolean
    $mentorTimeLogId: Int
    $timeLogAssociationId: Int
  ) {
    createStudentApplication(
      input: {
        collegeIds: $collegeIds
        StudentId: $StudentId
        studentOtherApplication: $studentOtherApplication
        mentorTimeLogId: $mentorTimeLogId
        timeLogAssociationId: $timeLogAssociationId
      }
    ) {
      status
    }
  }
`;

export const GET_MENTOR_PAYMENT_CATEGORIES = gql`
  query getMentorPaymentCategories($id: Int) {
    mentorPaymentCategories(id: $id) {
      id
      rate
      PaymentCategoryId
      paymentCategory {
        name
      }
    }
  }
`;
