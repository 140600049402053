import { createStyles, makeStyles, Theme, withStyles } from '@material-ui/core';

import fonts from '../../../../common/fonts';
import colors from '../../../../common/colors';

import CALENDAR_IMG from '../../../../img/calendar-icon-opp.svg';
import DROPDOWN_ARROW_IMG from '../../../../img/dropdown-arrow.svg';
import DropdownArrowErrorIcon from '../../../../img/dropdown-arrow-error.svg';
import Tooltip, { TooltipProps } from '@material-ui/core/Tooltip';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    activityPerformed: {
      height: 34,

      '& .MuiInputBase-fullWidth .MuiSelect-select.MuiSelect-selectMenu': {
        height: 34,
        padding: '6px 10px',
      },
    },

    logHoursCheckbox: {
      paddingLeft: 45,

      '& label': {
        fontSize: 14,
        margin: 0,
        fontWeight: 400,
        color: '#7D7F7F',
        fontFamily: fonts.INTER,
        letterSpacing: '-0.084px',
        cursor: 'pointer',
      },

      '& svg path': {
        fill: '#7D7F7F',
      },
    },

    MuiErrorField: {
      '& .MuiInputBase-formControl.MuiInput-formControl input': {
        border: '1px solid #E94444 !important',
      },
    },

    addNewApplications: {
      padding: '0px 0px 0px 55px',

      '& button': {
        padding: 8,
        fontSize: 14,
        fontWeight: 600,
        color: '#2F80ED',
        letterSpacing: '0.006em',
        textTransform: 'uppercase',
        fontFamily: fonts.POPPINS_FONT,

        '& img': {
          marginRight: 12,
        },
      },
    },

    applicationText: {
      fontSize: 12,
      marginTop: 4,
      fontWeight: 400,
      color: '#A9A9A9',
      fontFamily: fonts.INTER,
    },

    newApplications: {
      '& .labelText': {
        fontSize: 12,
        fontWeight: 400,
        marginBottom: 4,
        color: '#272929',
        letterSpacing: '-0.006em',
        fontFamily: fonts.POPPINS_FONT,
      },

      '& .react-select__multi-value': {
        borderRadius: 6,
        backgroundColor: colors.BLUE_SEVEN,
      },

      '& .react-select__multi-value__label': {
        fontSize: 14,
        fontWeight: 600,
        color: '#FFFFFF',
        letterSpacing: '-0.006em',
      },

      '& .react-select__clear-indicator': {
        display: 'none',
      },

      '& .react-select__multi-value__remove': {
        borderRadius: '0px 6px 6px 0px',
        backgroundColor: `${colors.BLUE_SEVEN} !important`,

        '& svg': {
          width: 21,
          height: 21,
          opacity: 0.7,
          color: '#fff',
        },
      },
    },

    dateDuration: {
      '& .activityLog': {
        fontSize: 12,
        color: '#A9A9A9',
        fontWeight: 400,
        marginBottom: 6,
        fontFamily: fonts.INTER,
      },
    },

    formRightExpected: {
      '& .offlineHoursPadding': {
        paddingLeft: '2.5rem',

        [theme.breakpoints.down('sm')]: {
          paddingLeft: '8px',
        },
      },
    },

    expectedPayment: {
      '& .MuiFormControlLabel-label': {
        fontWeight: 400,
        fontSize: 12,
        color: '#7D7F7F',
        lineHeight: '16px',
        fontFamily: fonts.INTER,
        textAlign: 'justify',
      },

      '& .MuiFormControlLabel-root': {
        marginBottom: 0,
        alignItems: 'flex-start',
      },

      '& svg path': {
        color: '#7D7F7F',
      },

      '& .MuiCheckbox-root': {
        position: 'relative',
        bottom: 9,
      },
    },

    feedbackCheckbox: {
      '& .MuiFormControlLabel-label': {
        fontSize: 12,
        fontWeight: 400,
        color: '#7D7F7F',
        letterSpacing: '-0.006em',
      },

      '& a': {
        color: colors.BLUE_SEVEN,
      },

      '& svg': {
        width: 18,
        color: colors.BLACK_FOURTEEN,
      },
      '& button': {
        background: 'none!important',
        border: 'none',
        padding: '0!important',
        color: '#069',
        textDecoration: 'underline',
        cursor: 'pointer',
      },
    },

    headingSpacingLogs: {
      marginTop: '69px !important',

      '@media only screen and (max-width: 767px)': {
        marginTop: '0px !important',
      },
    },

    stripeContentDisable: {
      opacity: 0.25,
      cursor: 'not-allowed',
      zIndex: -1,

      '&:hover': {
        opacity: '0.25 !important',
      },
    },

    unavailableAccount: {
      background: '#FFEFEB !important',
    },

    stripeTokenCard: {
      background: '#FFF8E4',
      borderRadius: 15,
      padding: '23px 20px',
      boxShadow: 'none',
      marginBottom: 43,

      '@media only screen and (max-width: 767px)': {
        padding: 15,
        margin: '15px 0px',
      },

      '& img': {
        marginRight: 20,
        position: 'relative',
        bottom: 4,
      },

      '& .MuiTypography-h5, .MuiTypography-body1, .MuiButton-root': {
        fontFamily: 'Poppins',
        fontSize: 14,
        letterSpacing: '-0.006em',
      },

      '& .MuiTypography-h5': {
        fontWeight: 600,
        color: '#272929',
      },

      '& .MuiTypography-body1': {
        fontWeight: 'normal',
        color: '#62636B',
        margin: '4px 0px 12px',
      },

      '& .MuiButton-root': {
        background: '#2F80ED',
        borderRadius: 6,
        color: '#FFFFFF',
        padding: '8px 15px',
        textTransform: 'capitalize',
      },
    },

    logHoursResponsive: {
      '@media only screen and (max-width: 767px)': {
        padding: '40px 20px 20px',
      },
    },

    containerResponsiveDevice: {
      '@media only screen and (max-width: 767px)': {
        padding: '0px',
      },
    },

    formBoxCard: {
      paddingTop: 40,
      marginBottom: 32,

      '@media only screen and (max-width: 767px)': {
        padding: '0px',
      },
    },

    logHoursSubHeading: {
      fontSize: 16,
      marginBottom: 35,
      color: '#272929',
      fontFamily: fonts.INTER,

      '@media only screen and (max-width: 767px)': {
        textAlign: 'center',
        fontWeight: 600,
      },
    },

    successCardBox: {
      '& .MuiTypography-h3': {
        fontSize: 36,
        fontWeight: 700,
        display: 'flex',
        color: '#272929',
        alignItems: 'center',
        padding: '62px 0px 16px',
        letterSpacing: '-0.792px',
        fontFamily: fonts.POPPINS_FONT,

        [theme.breakpoints.down('xs')]: {
          padding: '30px 0px 16px',
        },

        '& svg': {
          marginLeft: 14,
        },
      },

      '& .submittedText': {
        fontSize: 16,
        maxWidth: 691,
        fontWeight: 400,
        color: '#7D7F7F',
        marginBottom: 29,
        fontFamily: fonts.INTER,
        letterSpacing: '-0.176px',

        '& a': {
          borderBottom: `1px solid ${colors.BLUE_SEVEN}`,

          '&:hover': {
            color: colors.BLUE_SEVEN,
          },
        },
      },

      '& .sectionDivider': {
        maxWidth: 400,
        marginBottom: 32,
        backgroundColor: '#D4D4D4',
      },
      '& ul': {
        color: colors.BLACK_THIRTEEN,
        paddingLeft: '0px',
      },
    },

    previousArrowHeading: {
      '@media only screen and (max-width: 767px)': {
        display: 'flex',
        alignItems: 'center',
        borderBottom: '1px solid #c4c4c4',
        padding: '25px 20px',
        minHeight: 75,
      },

      '& .MuiTypography-h3': {
        fontFamily: fonts.POPPINS_FONT,
        fontWeight: 600,
        fontSize: 36,
        color: '#272929',
        margin: '45px 0px 54px',

        '@media only screen and (max-width: 767px)': {
          margin: '0px',
          flex: 1,
          textAlign: 'center',
          fontWeight: 'bold',
          fontSize: 16,
        },
      },
    },

    logHoursAlert: {
      '& .MuiAlert-root': {
        borderRadius: 15,
        maxWidth: 372,
        width: '100%',
        fontFamily: 'Poppins',
        fontWeight: 500,
        fontSize: 14,
        color: '#000000',
        padding: '18px 21px',
        marginTop: 20,

        '& .MuiTypography-h5': {
          fontFamily: 'Poppins',
          fontWeight: 600,
          fontSize: 14,
          color: '#000000',
        },

        '& .MuiAlert-icon': {
          display: 'none',
        },

        '& ul': {
          margin: '4px 0px 0px 0px',
          paddingLeft: 53,

          '& li': {
            fontWeight: 'normal',
            fontSize: 14,
            letterSpacing: '-0.006em',
            color: '#62636B',
          },
        },

        '& .MuiAlert-message': {
          padding: 0,
        },

        '& img': {
          marginRight: 20,
        },
      },

      '& .MuiAlert-standardError': {
        background: 'rgba(233, 68, 68, 0.15)',
      },

      '& .MuiAlert-standardWarning': {
        background: '#FFF8E4',
      },
    },

    formContainer: {
      '& .MuiFormControl-root': {
        width: '100%',
      },
      '& .MuiInputBase-root': {
        width: '100%',
      },

      '& .MuiRadio-colorSecondary.Mui-checked': {
        color: colors.BLUE_SEVEN,
        background: 'none !important',
      },

      '& .MuiButton-containedPrimary': {
        background: `${colors.BLUE_SEVEN} !important`,
      },

      '& .MuiFormLabel-root.Mui-focused': {
        color: colors.BLUE_SEVEN,
      },

      '& .MuiInput-underline:after': {
        borderBottom: `2px solid ${colors.BLUE_SEVEN}`,
      },

      '& .onlineMeetingVerified': {
        fontSize: 12,
        fontWeight: 400,
        color: '#7D7F7F',
        fontFamily: fonts.INTER,
        letterSpacing: '-0.006em',
        margin: '0px 10px 0px 12px',
      },

      '& .textActionButton': {
        padding: 0,
        fontSize: 12,
        fontWeight: 400,
        color: '#2F80ED',
        fontFamily: fonts.INTER,
        letterSpacing: '-0.006em',
        textTransform: 'capitalize',
      },
    },

    logHoursButtons: {
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'center',
      maxWidth: 800,
      width: '100%',
      justifyContent: 'space-between',

      '& > div': {
        display: 'flex',
        alignItems: 'center',
        maxWidth: 380,
        width: '100%',
        background: '#FFFFFF',
        borderRadius: 10,
        marginBottom: 26,
        color: '#272929',
        fontSize: 16,
        fontWeight: 500,
        cursor: 'pointer',
        boxShadow: '0px 0px 8px 0.5px rgb(0 0 0 / 25%)',
        padding: 19,
        minHeight: 96,

        '&:last-child': {
          justifyContent: 'center',
        },

        '& .MuiTypography-h5': {
          fontWeight: 600,
          fontSize: 16,
          color: '#272929',
          marginBottom: 3,
          fontFamily: 'Poppins',
        },

        '& .MuiTypography-subtitle1': {
          fontWeight: 'normal',
          fontSize: 14,
          color: '#62636B',
          lineHeight: '16px',
          fontFamily: 'Poppins',
        },

        '& img': {
          marginRight: 23,
        },

        [theme.breakpoints.down('sm')]: {
          maxWidth: '100%',
        },

        '&:hover': {
          opacity: 0.8,
        },
      },
    },

    pointer: {
      cursor: 'pointer',
    },

    formInner: {
      display: 'flex',
      alignItems: 'flex-start',

      [theme.breakpoints.down('md')]: {
        display: 'block',
      },

      '& .MuiFormHelperText-root.Mui-error': {
        lineHeight: '16px',
        fontFamily: fonts.INTER,
        textAlign: 'right',
      },

      '@media only screen and (max-width: 767px)': {
        padding: '40px 0px 20px',
      },
    },

    formLeft: {
      maxWidth: 354,
      width: '100%',
      marginRight: 45,

      [theme.breakpoints.down('sm')]: {
        marginRight: 0,
        maxWidth: '100%',
      },

      '& .notesDescription': {
        fontSize: 12,
        fontWeight: 400,
        marginBottom: 6,
        color: '#A9A9A9',
        fontFamily: fonts.INTER,
      },

      '& .MuiSelect-root.Mui-disabled': {
        backgroundColor: '#ededed !important',
      },

      '& .MuiSelect-root.Mui-disabled + .MuiSelect-nativeInput': {
        backgroundColor: '#ededed !important',
      },

      '& .MuiFormControlLabel-root': {
        marginTop: -17,
        marginBottom: 6,

        '& .MuiFormControlLabel-label': {
          fontWeight: 400,
          fontSize: 14,
          letterSpacing: '-0.011em',
          color: '#7D7F7F',
          fontFamily: fonts.INTER,
        },
      },

      '& .MuiCheckbox-colorPrimary': {
        color: '#7D7F7F',
      },
    },

    formRight: {
      '& .studentEntitlementsHeading': {
        fontSize: 18,
        fontWeight: 600,
        color: '#272929',
        marginBottom: 33,
        fontFamily: fonts.INTER,
        letterSpacing: '-0.014em',

        '& span': {
          fontWeight: 400,
        },
      },

      '& .amountText': {
        fontSize: 24,
        fontWeight: 600,
        color: '#272929',
        letterSpacing: '-0.019em',
        fontFamily: fonts.POPPINS_FONT,
      },
    },

    logHoursCard: {
      borderRadius: 25,
      boxShadow: 'none',
      padding: '43px 50px',
      border: '1px solid #D4D4D4',

      [theme.breakpoints.down('sm')]: {
        padding: 20,
      },

      '& .dividerSeparator': {
        background: '#d4d4d4',
        margin: '15px 0px 48px',
      },
    },

    textfieldDateDuration: {
      marginBottom: '0px !important',
    },

    subSectionPadding: {
      paddingLeft: 56,

      [theme.breakpoints.down('xs')]: {
        paddingLeft: 0,
      },

      '& .noMeetingAssociate': {
        paddingTop: 40,
      },
    },

    textfield: {
      marginBottom: 17,
      fontSize: 14,

      '& > label': {
        fontSize: 12,
        fontWeight: 400,
        marginBottom: 4,
        fontFamily: fonts.INTER,
        letterSpacing: '-0.006em',
        color: '#272929 !important',
        display: 'flex',
        alignItems: 'center',
        gap: 4,
      },

      '& .select-field > div > div': {
        boxShadow: '0px 0px 3px 0.5px rgba(0, 0, 0, 0.25)',
        borderRadius: 5,
        border: 'none',
        fontSize: 14,
        fontFamily: fonts.INTER,
      },

      '& .MuiInputBase-formControl.MuiInput-formControl input': {
        height: 32,
        fontSize: 14,
        borderRadius: 6,
        boxShadow: 'none',
        fontWeight: 400,
        color: '#272929',
        padding: '0px 10px',
        fontFamily: fonts.INTER,
        letterSpacing: '-0.006em',
        border: '1px solid #d4d4d4',
      },

      '& .MuiInputBase-fullWidth': {
        '& .MuiSelect-select.MuiSelect-selectMenu': {
          paddingLeft: 10,
          background: 'none',
          fontSize: 14,
          fontFamily: fonts.INTER,
          boxShadow: 'none',
          borderRadius: 6,
          paddingRight: 10,
          border: '1px solid #d4d4d4',
          boxSizing: 'border-box',
        },
      },

      '& .MuiInput-underline:after': {
        display: 'none',
      },

      '& .MuiInput-underline:before': {
        display: 'none',
      },

      '& .MuiCheckbox-colorPrimary': {
        color: '#7D7f7f',

        '&.Mui-checked': {
          color: '#7D7f7f',
        },
      },

      '& .MuiErrorField .MuiSelect-select.MuiSelect-selectMenu': {
        border: '1px solid #E94444 !important',
      },

      '& .MuiErrorField.MuiInputBase-root': {
        backgroundImage: `url(${DropdownArrowErrorIcon})`,
      },

      '& button.MuiButtonBase-root.MuiIconButton-root': {
        position: 'absolute',
        right: 15,
        padding: 0,
      },

      '&.with-textarea': {
        '& .MuiInputBase-fullWidth': {
          height: 'auto',
          border: '1px solid #d4d4d4',
          borderRadius: 6,
          fontSize: 14,

          '& textarea': {
            padding: '0 10px',
          },
        },
      },

      '&.with-topic': {
        '& > label': {
          width: 110,
          margin: 0,
          textAlign: 'right',
          paddingRight: 13,
        },
      },
      '&.hide-error': {
        '& div p': {
          display: 'none',
        },
      },
    },

    durationField: {
      '& .MuiInputBase-fullWidth': {
        boxShadow: 'none !important',
      },
    },

    missedCallCheckbox: {
      '& .MuiTypography-body1': {
        fontSize: 12,
        color: '#62636B',
        fontFamily: fonts.INTER,
      },
    },

    addButton: {
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      fontSize: 14,
      color: colors.BLUE_SEVEN,
      marginBottom: 17,

      '& > span': {
        marginLeft: 14,
      },

      '&.disable': {
        color: colors.BLUE_SEVEN,
        cursor: 'not-allowed',
        opacity: 0.6,
      },
    },

    submitButton: {
      width: 232,
      height: 40,
      background: '#45CE93 !important',
      borderRadius: '12px !important',
      fontSize: `14px !important`,
      fontWeight: 600,
      color: '#ffffff !important',
      fontFamily: `${fonts.POPPINS_FONT} !important`,

      '&:hover': {
        boxShadow: 'none',
        backgroundColor: `${colors.GREEN_BUTTON_HOVER} !important`,
      },

      '&:disabled': {
        opacity: 0.25,
        cursor: 'not-allowed',
      },

      '@media only screen and (max-width: 767px)': {
        width: '100%',
      },
    },

    textFieldMaterial: {
      '& .MuiInputBase-root': {
        backgroundSize: 12,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center right 12px',
        backgroundImage: `url(${DROPDOWN_ARROW_IMG})`,

        '&.Mui-disabled': {
          opacity: 0.6,
          borderColor: 'hsl(0, 0%, 90%)',
          backgroundColor: 'transparent',

          '& .MuiSelect-nativeInput': {
            backgroundColor: '#fff',
          },
        },

        '& .MuiSvgIcon-root': {
          display: 'none',
        },
      },

      '& .MuiSelect-nativeInput': {
        border: 0,
        paddingLeft: 10,
        fontFamily: fonts.INTER,
        fontSize: 14,
        fontWeight: 400,
        width: '90%',
        bottom: 6,
        left: 5,
        borderRadius: '6px 0px 0px 6px',

        '&::placeholder': {
          fontSize: 14,
          color: '#A9A9A9',
        },
      },
    },

    textFieldDate: {
      '& .MuiInputBase-root': {
        '& .MuiSvgIcon-root': {
          backgroundPosition: '3px',
          backgroundRepeat: 'no-repeat',
          backgroundImage: `url(${CALENDAR_IMG})`,

          '& path': {
            display: 'none',
          },
        },
      },
    },

    disableTextFieldDate: {
      '& .MuiInputBase-root': {
        '& .MuiInput-input': {
          color: '#A9A9A9 !important',
          backgroundColor: colors.GRAY_BOREDER,
          border: '1px solid #D4D4D4 !important',
        },
      },
    },

    disableLabelText: {
      opacity: 0.5,
    },

    customDropdownField: {
      '& .css-1okebmr-indicatorSeparator': {
        backgroundColor: 'transparent',
      },

      '& .css-yk16xz-control': {
        border: 0,
        paddingLeft: 20,
        backgroundColor: 'transparent',
      },
    },

    activityPendingCard: {
      background: '#EBF3FE',

      '& .MuiTypography-body1': {
        color: '#7D7F7F',
        letterSpacing: '-0.006em',
      },
    },

    studentApplicationsSelect: {
      '& .react-select__multi-value': {
        backgroundColor: '#A9A9A9 !important',
      },

      '& .react-select__multi-value__remove:hover': {
        cursor: 'pointer',
        backgroundColor: '#A9A9A9',
      },

      '& .react-select__dropdown-indicator': {
        width: '33px',
        height: '33px',
        backgroundSize: 12,
        backgroundRepeat: 'no-repeat',
        backgroundColor: 'transparent',
        backgroundPosition: 'center right 12px',
        backgroundImage: `url(${DROPDOWN_ARROW_IMG})`,

        '& svg': {
          display: 'none',
        },
      },

      '& .react-select__placeholder': {
        fontSize: 14,
        fontWeight: 400,
        color: '#A9A9A9',
        fontFamily: fonts.INTER,
        letterSpacing: '-0.006em',
      },
    },

    secondAdvisorApplication: {
      paddingLeft: 55,
      marginBottom: 17,

      '& .react-select__multi-value': {
        borderRadius: 6,
        backgroundColor: '#A9A9A9',
      },

      '& .react-select__placeholder': {
        fontSize: 14,
        fontWeight: 400,
        color: '#A9A9A9',
        fontFamily: fonts.INTER,
        letterSpacing: '-0.006em',
      },

      '& .react-select__control': {
        borderRadius: 6,
        boxShadow: 'none',
        borderColor: '#d4d4d4',
      },

      '& .react-select__indicator-separator': {
        display: 'none',
      },

      '& .react-select__multi-value__label': {
        fontSize: 14,
        fontWeight: 600,
        color: '#FFFFFF',
        letterSpacing: '-0.006em',
      },

      '& .react-select__clear-indicator': {
        display: 'none',
      },

      '& .react-select__multi-value__remove': {
        backgroundColor: '#A9A9A9',
        borderRadius: '0px 6px 6px 0px',

        '&:hover': {
          backgroundColor: '#A9A9A9 !important',
        },

        '& svg': {
          width: 21,
          height: 21,
          opacity: 0.7,
          color: '#fff',
        },
      },
    },

    secondAdvisor: {
      paddingLeft: 55,
      marginBottom: 17,

      '& .react-select__placeholder': {
        fontSize: 14,
        fontWeight: 400,
        color: '#A9A9A9',
        fontFamily: fonts.INTER,
        letterSpacing: '-0.006em',
      },

      '& .react-select__indicator': {
        padding: '0px 8px',
      },

      '& .react-select__value-container': {
        fontSize: 14,
        fontWeight: 400,
        color: '#272929',
        fontFamily: fonts.INTER,
        letterSpacing: '-0.006em',
      },

      '& .react-select__control': {
        minHeight: 34,
        borderRadius: 6,
        boxShadow: 'none',
        borderColor: '#d4d4d4',
      },

      '& .react-select__indicator-separator': {
        display: 'none',
      },

      '& .react-select__multi-value': {
        borderRadius: 6,
        backgroundColor: colors.BLUE_SEVEN,
      },

      '& .react-select__multi-value__label': {
        fontSize: 14,
        fontWeight: 600,
        color: '#FFFFFF',
        fontFamily: fonts.INTER,
        letterSpacing: '-0.006em',
      },

      '& .react-select__clear-indicator': {
        display: 'none',
      },

      '& .react-select__multi-value__remove:hover': {
        cursor: 'pointer',
        backgroundColor: colors.BLUE_SEVEN,
      },

      '& .react-select__multi-value__remove': {
        borderRadius: '0px 6px 6px 0px',

        '& svg': {
          width: 21,
          height: 21,
          opacity: 0.7,
          color: '#fff',
        },
      },

      '& .react-select__dropdown-indicator': {
        width: '33px',
        height: '33px',
        backgroundSize: 12,
        backgroundRepeat: 'no-repeat',
        backgroundColor: 'transparent',
        backgroundPosition: 'center right 12px',
        backgroundImage: `url(${DROPDOWN_ARROW_IMG})`,

        '& svg': {
          display: 'none',
        },
      },
    },

    activityPending: {
      '&.Mui-disabled': {
        color: 'rgba(0, 0, 0, 0.26)',
        boxShadow: 'none',
        backgroundColor: 'rgba(0, 0, 0, 0.12)',
      },
    },

    timeLogData: {
      fontSize: 14,
      fontWeight: 400,
      color: '#A9A9A9',
      fontFamily: fonts.INTER,
      letterSpacing: '-0.084px',
      '& ul': {
        color: `${colors.BLACK_THIRTEEN} !important`,
        paddingLeft: '12px',
        marginBottom: '8px',
      },

      '& .valueText': {
        color: '#7D7F7F',

        '& b': {
          color: '#525454',
        },
        '&.note': {
          marginLeft: '12px',
        },
      },
    },

    hintIcon: {
      '&:hover': {
        cursor: 'pointer',
      },
    },

    tip: {
    fontSize: 13,
    '& b': {
        fontWeight: 800,
        color: colors.BLACK_SIX,
      },
    },

    tipList: {
      padding: '0 20px',
    }
  })
);

export const BACK_IC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="18"
      viewBox="0 0 21 18"
      fill="none"
    >
      <path
        opacity="0.5"
        d="M20.125 8.10012H2.66919L9.35331 1.5518C9.70331 1.2089 9.71688 0.639212 9.3835 0.279216C9.05056 -0.0803296 8.49669 -0.0947295 8.14625 0.248166L0.51275 7.72708C0.182437 8.06727 0 8.51907 0 9.00011C0 9.4807 0.182437 9.93295 0.528062 10.288L8.14669 17.7516C8.316 17.9177 8.533 18 8.75 18C8.981 18 9.212 17.9064 9.38394 17.7206C9.71731 17.3606 9.70375 16.7913 9.35375 16.4484L2.64162 9.9001H20.125C20.608 9.9001 21 9.4969 21 9.00011C21 8.50332 20.608 8.10012 20.125 8.10012Z"
        fill="#62636B"
      />
    </svg>
  );
};

export const ActivityNotesTip = withStyles((theme: Theme) => ({
  tooltip: {
    cursor: 'pointer',
    backgroundColor: colors.WHITE_ONE,
    color: colors.BLACK_THIRTEEN,
    fontSize: 12,
    padding: 20,
    minWidth: 336,
    boxShadow: `4px 4px 40px ${colors.GRAY_ONE}`,
  },
  arrow: {
    color: colors.WHITE_ONE,
  },
}))(Tooltip);
