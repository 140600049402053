import DateFnsUtils from '@date-io/date-fns';

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { FormHelperText } from '@material-ui/core';

const NativeTimeTextField = ({
  field: { name, value },
  form: { setFieldValue, touched, errors },
  minDate,
  maxDate,
  label,
  disable,
}: any) => {
  return (
    <>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          label={label || ''}
          disabled={disable}
          disableToolbar
          variant="inline"
          format="MM/dd/yyyy"
          maxDate={maxDate || undefined}
          minDate={minDate || undefined}
          value={value}
          onChange={(date: Date | null) => {
            setFieldValue(name, date);
          }}
          placeholder="mm/dd/yyyy"
        />
      </MuiPickersUtilsProvider>
      {touched[name] && errors[name] && errors[name] === 'Requried' && (
        <FormHelperText error>Required</FormHelperText>
      )}
    </>
  );
};

export default NativeTimeTextField;
