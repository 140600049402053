import {
  Box,
  Button,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import LoopIcon from '@material-ui/icons/Loop';
import _ from 'lodash';
import moment from 'moment';
import momentTz from 'moment-timezone';
import React, { useContext, useEffect, useState } from 'react';
import { Query } from 'react-apollo';

import Colors from '../../../common/colors';
import Loader from '../../../components/Loader';
import { AppContext } from '../../../contexts';
import { useStyles } from '../../../styles/tableStyle';
import TimeSlotsDialog from './TimeSlotsDialog';
import { dayClickedType, groupingOfTimeSlots, weekdays } from './utils';

import Fonts from '../../../common/fonts';
import Arrow_Left_Img from '../../../img/arrow-left.svg';
import Arrow_Right_Img from '../../../img/arrow-slots.svg';
import Plus_icon from '../../../img/plus-icon.svg';
import ConnectToCalendarDialog from './ConnectToCalendarDialog';

import { IChartData } from '../../../common/interfaces';
import {
  getSetCapacityModalData,
  graphApiCalling,
  updateAdvisorCapacity,
} from '../../../common/utility';
import SetCapacityModal from '../../../components/capacity/SetCapacityModal';
import { MENTOR_TIME_SLOTS, NEW_TIMESLOT_QUERY } from './gql';
import { getAdvisorMonthlyTImeSlotsQuery } from './gql/__generated__/getAdvisorMonthlyTImeSlotsQuery';
import {
  mentorTimeSlotsQuery,
  mentorTimeSlotsQuery_mentorTimeSlots_timeSlots,
} from './gql/__generated__/mentorTimeSlotsQuery';

const styles = makeStyles(() =>
  createStyles({
    mainTable: {
      opacity: 0.35,
    },

    onHoverBorders: {
      position: 'absolute',
      height: '100%',
      width: '100%',
      background: '#EBF3FE',
      zIndex: 1,
      top: 0,
      left: 0,
      display: 'none',
    },

    timeBox: {
      border: '1px solid #A9A9A9',
      boxSizing: 'border-box',
      verticalAlign: 'top',
      cursor: 'pointer',
      position: 'relative',
      width: 174,
      height: 174,
      padding: '10px 10px',
      backgroundColor: 'transparent !important',

      '&:hover': {
        '& .borders': {
          display: 'block',
        },
        '& .MuiButton-root': {
          display: 'block',
        },
      },
    },

    dayData: {
      position: 'relative',
      zIndex: 9,

      '& .MuiTypography-caption': {
        fontFamily: Fonts.INTER,
        fontSize: 12,
        color: '#7D7F7F',
        width: '100%',
        display: 'block',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },

      '& .MuiButton-text': {
        fontFamily: Fonts.POPPINS_FONT,
        fontWeight: 600,
        fontSize: 10,
        letterSpacing: '0.018em',
        textTransform: 'uppercase',
        color: '#2F80ED',
        padding: 0,
        display: 'none',

        '& img': {
          marginRight: 5,
        },
      },
    },

    withBorders: {
      position: 'absolute',
      height: '100%',
      width: '100%',
      background: '#EBF3FE',
      zIndex: 1,
      top: 0,
      left: 0,

      '& + div > .dayDate': {
        color: Colors.BLUE_SEVEN,
        fontWeight: 700,
        textTransform: 'uppercase',
      },
    },
  })
);

const TimeSlotsTable = ({
  selectedAdvisorId,
}: {
  selectedAdvisorId: number | null;
}) => {
  const classes = useStyles();
  const { user } = useContext(AppContext);
  const [currentMonth, setCurrentMonth] = useState<Date>(new Date());
  const [dayClicked, setDayClicked] = useState<dayClickedType>({
    day: '',
    dayValues: [],
  });
  const [openTimeModal, setOpenTimeModal] = useState(false);
  const [isDayEnabeled, setIsDayEnabeled] = useState(true);
  const [currentViewTimeSlots, setCurrentViewTimeSlots] = useState<any>();
  const [currentDayTimeSlots, setCurrentDayTimeSlots] = useState<any>();
  const [chartData, setChartData] = useState<IChartData | null>(null);
  const { mainTable, timeBox, withBorders, dayData, onHoverBorders } = styles();
  function getCurrentDayTimeSlots(day: string) {
    let slots: any = [];
    if (currentViewTimeSlots?.length)
      for (const currentViewTimeSlot of currentViewTimeSlots) {
        currentViewTimeSlot?.values?.forEach(
          (value: any) => value.key === day && slots.push(value)
        );
      }

    setCurrentDayTimeSlots(slots);
  }

  const urlParams = new URLSearchParams(window.location.search);

  const addTimeZoneSign = (date: string | null) => {
    if (!date) return null;
    const dateArray = date.split('(');
    const dateSplit = dateArray[0].split(' ');
    const offset = dateSplit[dateSplit?.length - 2];
    if (offset?.length === 4) {
      return date.replace(offset, `+${offset}`);
    }
    return date;
  };
  const startDate = addTimeZoneSign(urlParams.get('sD'));
  const endDate = addTimeZoneSign(urlParams.get('eD'));

  const onPreviousClick = () => {
    let prevCount = 1;
    if (window.history.pushState) {
      let prevStartDate: any = moment(startDate || currentMonth)
        .startOf('month')
        .subtract(prevCount++, 'month');
      prevStartDate = new Date(moment(prevStartDate).format());
      let prevEndDate: any = moment(prevStartDate).endOf('month');

      prevEndDate = new Date(moment(prevEndDate).format());
      setCurrentMonth(prevStartDate);
      setCurrentViewTimeSlots(null);

      let prevUrl =
        window.location.protocol +
        '//' +
        window.location.host +
        window.location.pathname +
        `?sD=${prevStartDate}&eD=${prevEndDate}`;
      window.history.pushState({ path: prevUrl }, '', prevUrl);
    }
  };

  const onNextClick = () => {
    if (window.history.pushState) {
      let nextCount = 1;
      let nextStartDate: any = moment(startDate || currentMonth)
        .startOf('month')
        .add(nextCount++, 'month');
      nextStartDate = new Date(moment(nextStartDate).format());
      let nextEndDate: any = moment(nextStartDate).endOf('month');

      nextEndDate = new Date(moment(nextEndDate).format());
      setCurrentMonth(nextStartDate);
      setCurrentViewTimeSlots(null);
      let nextUrl =
        window.location.protocol +
        '//' +
        window.location.host +
        window.location.pathname +
        `?sD=${nextStartDate}&eD=${nextEndDate}`;
      window.history.pushState({ path: nextUrl }, '', nextUrl);
    }
  };

  const onTodayClick = () => {
    if (
      window.history.pushState &&
      moment(startDate).format('MM-DD-YYYY') !==
        moment(new Date()).startOf('month').format('MM-DD-YYYY')
    ) {
      let prevStartDate: any = moment(new Date()).startOf('month');
      prevStartDate = new Date(moment(prevStartDate).format());
      let prevEndDate: any = moment(new Date()).endOf('month');

      prevEndDate = new Date(moment(prevEndDate).format());
      setCurrentMonth(prevStartDate);

      let prevUrl =
        window.location.protocol +
        '//' +
        window.location.host +
        window.location.pathname +
        `?sD=${prevStartDate}&eD=${prevEndDate}`;
      moment(startDate).format('MM-DD-YYYY') !==
        moment(new Date()).startOf('month').format('MM-DD-YYYY') &&
        window.history.pushState({ path: prevUrl }, '', prevUrl);
    }
  };

  const updateAdvisorCapacityData = async ({
    hours,
    matchStr,
    isOpenToTakeStudents,
  }: {
    hours: string;
    matchStr: string;
    isOpenToTakeStudents: boolean;
  }) => {
    try {
      const capacityData = await updateAdvisorCapacity({
        hours,
        isOpenToTakeStudents,
        matchStr,
      });
      if (capacityData) {
        await apiCall();
      }
    } catch (err: any) {
      console.log('ERROR:', err.message);
    }
  };

  const apiCall = async () => {
    try {
      const data = await graphApiCalling();
      if (data) setChartData(data);
    } catch (err: any) {
      console.log('ERROR:', err.message);
    }
  };

  const mountEffect = () => {
    if(!currentViewTimeSlots)
      apiCall();
    if (dayClicked.day && currentViewTimeSlots?.length) {
      getCurrentDayTimeSlots(dayClicked.day);
    }
  };

  useEffect(mountEffect, [currentViewTimeSlots]);

  let estimatedActivity: number | null = null;
  let minAvg: number | null = null;
  let maxAvg: number | null = null;
  let matchText: string | null = null;
  let hours: string | undefined;
  let hoursLastUpdate: Date | undefined;

  if (chartData) {
    const { max, estimation } = chartData;
    const data = getSetCapacityModalData(estimation);
    const avg = data.avg;
    estimatedActivity = avg;
    minAvg = data.minAvg;
    maxAvg = data.maxAvg;
    matchText = max?.matchText || '';
    hours = max?.hours || '';
    hoursLastUpdate = max?.hoursLastUpdate || '';
  }

  return (
    <Query<getAdvisorMonthlyTImeSlotsQuery>
      query={NEW_TIMESLOT_QUERY}
      variables={{
        advisorId: selectedAdvisorId || user?.id,
        startDate: startDate,
        endDate: endDate,
        offset: moment().utcOffset() / 60,
      }}
      fetchPolicy="network-only"
    >
      {({ data, loading, refetch: refetchAdvisorMonthlySlots }) => {
        if (loading) return <Loader />;

        const newMonthlyTimeSlots =
          data?.getAdvisorMonthlyTImeSlots?.timeslots || [];

        const recurringDates: null[] = [];

        return (
          <Query<mentorTimeSlotsQuery>
            query={MENTOR_TIME_SLOTS}
            variables={{
              startDate: startDate,
              endDate: endDate,
              mentorId: selectedAdvisorId || user?.id,
            }}
            fetchPolicy="no-cache"
            onCompleted={(data) => {
              if (data.mentorTimeSlots) {
                const {
                  mentorTimeSlots: { timeSlots: slots },
                } = data;

                const timeSlots: (mentorTimeSlotsQuery_mentorTimeSlots_timeSlots | null)[] =
                  groupingOfTimeSlots(
                    _.cloneDeep(newMonthlyTimeSlots),
                    recurringDates,
                    _.cloneDeep(slots || [])
                  );
                setCurrentViewTimeSlots(timeSlots);
              }
            }}
          >
            {({ data, loading, refetch }) => {
              if (loading) return <Loader />;
              if (
                !data ||
                !data.mentorTimeSlots ||
                !data.mentorTimeSlots.timeSlots
              )
                return null;

              const {
                mentorTimeSlots: { timeSlots: slots },
              } = data;

              const timeSlots: (mentorTimeSlotsQuery_mentorTimeSlots_timeSlots | null)[] =
                groupingOfTimeSlots(
                  _.cloneDeep(newMonthlyTimeSlots),
                  recurringDates,
                  _.cloneDeep(slots)
                );
              !currentViewTimeSlots?.length &&
                setCurrentViewTimeSlots(timeSlots);

              return (
                <>
                  <Box
                    display="flex"
                    alignItems="center"
                    className={classes.timeSlotTable}
                  >
                    <ConnectToCalendarDialog
                      open={!user?.zoomLink || !user.nylas_access_token}
                    />
                    <SetCapacityModal
                      open={!hours && !user?.isFullTime}
                      hours={hours}
                      updateAdvisorCapacityData={updateAdvisorCapacityData}
                      estimatedActivity={estimatedActivity || 0}
                      matchText={matchText || ''}
                      hoursLastUpdate={hoursLastUpdate}
                      minAvg={minAvg}
                      maxAvg={maxAvg}
                      isAvailabilityPage={true}
                    />
                    <Box display="flex" alignItems="center">
                      <Typography variant="h4">
                        {moment(startDate || currentMonth).format('MMMM YYYY')}
                      </Typography>

                      <Box>
                        <IconButton
                          aria-label="prev"
                          onClick={() => {
                            onPreviousClick();
                            refetchAdvisorMonthlySlots();
                            refetch();
                          }}
                        >
                          <img src={Arrow_Left_Img} alt="left" />
                        </IconButton>

                        <IconButton
                          aria-label="next"
                          onClick={() => {
                            onNextClick();
                            refetchAdvisorMonthlySlots();
                            refetch();
                          }}
                        >
                          <img src={Arrow_Right_Img} alt="right" />
                        </IconButton>
                      </Box>
                    </Box>

                    <Button
                      onClick={onTodayClick}
                      className="padding-0"
                      color="primary"
                    >
                      GO TO CURRENT DATE
                    </Button>

                    <Box className="timeZoneText">
                      <Typography variant="body1">
                        All Times in{' '}
                        {momentTz.tz(momentTz.tz.guess()).format('z')}
                      </Typography>
                    </Box>
                  </Box>

                  <Box className={classes.calendarMeeting}>
                    <Grid className={classes.calendarGrid}>
                      <Box className={classes.paper}>
                        <Table
                          aria-labelledby="mentorTimeLogs"
                          size={'medium'}
                          aria-label="enhanced table"
                          className={
                            !user?.zoomLink || !user.nylas_access_token
                              ? mainTable
                              : ''
                          }
                        >
                          <TableHead>
                            <TableRow>
                              {weekdays().map((item, index) => {
                                return (
                                  <TableCell
                                    key={index}
                                    className={`${classes.tableHead} tableHeadBg`}
                                  >
                                    {moment(item).format('ddd')}
                                  </TableCell>
                                );
                              })}
                            </TableRow>
                          </TableHead>

                          <TableBody>
                            {timeSlots &&
                              timeSlots.length &&
                              timeSlots.map((item) => {
                                if (!item) return null;
                                const { values: weekValues, key } = item;

                                return (
                                  <TableRow key={`${key}-weekKey`}>
                                    {weekValues &&
                                      weekValues.length > 0 &&
                                      weekValues.map((days, index) => {
                                        const month = startDate || currentMonth;
                                        if (!days) return null;
                                        const {
                                          values,
                                          key: day,
                                          bookingsCount,
                                        } = days;
                                        if (
                                          moment(month).month() + 1 ===
                                          moment(day).month()
                                        )
                                          return null;

                                        let dateDay = day
                                          ? moment(day).format('D')
                                          : '--';

                                        if (
                                          moment(day).isSame(moment(), 'day')
                                        ) {
                                          dateDay = 'Today';
                                        }

                                        let isEnabled = false;
                                        let isDisabled = false;
                                        let isPrevMonth = false;

                                        if (
                                          moment(day).isSameOrAfter(
                                            moment(new Date()),
                                            'day'
                                          )
                                        ) {
                                          isEnabled = true;
                                        }

                                        if (
                                          moment(day).isBefore(
                                            moment(
                                              startDate || new Date()
                                            ).startOf('month')
                                          )
                                        ) {
                                          isPrevMonth = true;
                                        }

                                        if (
                                          (!values?.length &&
                                            moment(
                                              moment(day).format('YYYY-MM-DD')
                                            ).isBefore(
                                              moment(new Date()).format(
                                                'YYYY-MM-DD'
                                              )
                                            )) ||
                                          !user?.nylas_access_token ||
                                          !user.zoomLink ||
                                          (selectedAdvisorId && !values?.length)
                                        ) {
                                          isDisabled = true;
                                          isEnabled = false;
                                        }
                                        const handleDayClicked = () => {
                                          setOpenTimeModal(true);
                                          setIsDayEnabeled(isEnabled);

                                          if (isDisabled || isPrevMonth) {
                                            setOpenTimeModal(false);
                                          }
                                          day && getCurrentDayTimeSlots(day);
                                          day &&
                                            setDayClicked({
                                              day,
                                              dayValues: values,
                                            });
                                        };
                                        return (
                                          <TableCell
                                            key={`${index}-days`}
                                            className={timeBox}
                                            style={{
                                              background: isPrevMonth
                                                ? 'none'
                                                : Colors.WHITE_ONE,
                                            }}
                                          >
                                            <Box
                                              display="flex"
                                              flexDirection="column"
                                              height="100%"
                                              onClick={() => {
                                                handleDayClicked();
                                              }}
                                              style={{
                                                opacity: isEnabled
                                                  ? isPrevMonth
                                                    ? 0.3
                                                    : 1
                                                  : isDisabled
                                                  ? 0.3
                                                  : 0.3,
                                                cursor:
                                                  isDisabled || isPrevMonth
                                                    ? 'not-allowed'
                                                    : 'pointer',
                                              }}
                                            >
                                              <Box
                                                className={`${onHoverBorders} borders`}
                                              />

                                              {dateDay === 'Today' && (
                                                <Box className={withBorders} />
                                              )}

                                              <Box className={dayData}>
                                                <Box
                                                  fontSize={12}
                                                  color="#9A9A9A"
                                                  fontWeight={600}
                                                  className="dayDate"
                                                >
                                                  {isPrevMonth ? '' : dateDay}
                                                </Box>

                                                <Typography
                                                  className={
                                                    classes.bookingsCount
                                                  }
                                                >
                                                  {!!values?.length &&
                                                    !!bookingsCount &&
                                                    `${bookingsCount} Meeting${
                                                      bookingsCount > 1
                                                        ? 's'
                                                        : ''
                                                    }`}
                                                </Typography>

                                                <Box flex={1}>
                                                  {values &&
                                                    values.length > 0 &&
                                                    values
                                                      .slice(0, 3)
                                                      .map(
                                                        (timeSlots, index) => {
                                                          if (!timeSlots)
                                                            return null;
                                                          const {
                                                            startDate,
                                                            endDate,
                                                            recurring,
                                                          } = timeSlots;
                                                          return (
                                                            <Box
                                                              key={`${index}-timeSlots`}
                                                            >
                                                              <Box
                                                                fontSize={12}
                                                                display="flex"
                                                                alignItems="center"
                                                                marginBottom={1}
                                                              >
                                                                <Grid
                                                                  className={
                                                                    classes.statusBadge
                                                                  }
                                                                ></Grid>

                                                                {recurring ? (
                                                                  <Tooltip title="Recurring Time Interval">
                                                                    <Box
                                                                      fontSize={
                                                                        16
                                                                      }
                                                                      marginBottom="2px"
                                                                    >
                                                                      <LoopIcon
                                                                        fontSize="inherit"
                                                                        color="primary"
                                                                      />
                                                                    </Box>
                                                                  </Tooltip>
                                                                ) : (
                                                                  <></>
                                                                )}
                                                                <Typography variant="caption">
                                                                  {`${moment(
                                                                    startDate
                                                                  ).format(
                                                                    'LT'
                                                                  )} - ${moment(
                                                                    endDate
                                                                  ).format(
                                                                    'LT'
                                                                  )}`}
                                                                </Typography>
                                                              </Box>
                                                            </Box>
                                                          );
                                                        }
                                                      )}

                                                  {isEnabled &&
                                                    !values?.length && (
                                                      <Button
                                                        variant="text"
                                                        onClick={
                                                          handleDayClicked
                                                        }
                                                      >
                                                        <img
                                                          src={Plus_icon}
                                                          alt="add"
                                                        />
                                                        ADD TIME SLOT
                                                      </Button>
                                                    )}
                                                </Box>

                                                {values &&
                                                  values.length > 3 && (
                                                    <Box
                                                      fontSize={12}
                                                      onClick={handleDayClicked}
                                                      textAlign="right"
                                                      marginTop={1}
                                                      color={Colors.BLUE_SEVEN}
                                                    >
                                                      View More
                                                    </Box>
                                                  )}
                                              </Box>
                                            </Box>
                                          </TableCell>
                                        );
                                      })}
                                  </TableRow>
                                );
                              })}
                          </TableBody>
                        </Table>
                      </Box>
                    </Grid>
                  </Box>

                  <TimeSlotsDialog
                    open={openTimeModal}
                    onClose={() => setOpenTimeModal(false)}
                    dayClicked={dayClicked}
                    month={timeSlots}
                    refetch={refetch}
                    refetchAdvisorMonthlySlots={refetchAdvisorMonthlySlots}
                    currentDayTimeSlots={currentDayTimeSlots}
                    isEditable={isDayEnabeled}
                    allMonthTimeSlots={newMonthlyTimeSlots}
                    selectedAdvisorId={selectedAdvisorId}
                  />
                </>
              );
            }}
          </Query>
        );
      }}
    </Query>
  );
};

export default React.memo(TimeSlotsTable);
